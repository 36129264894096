module.exports = {
  // TODO: Figure out which fields are actually needed here then remove the rest from all properties
  gatsbyConfig: {
    property: 'eldora',
    siteName: 'Eldora Mountain Resort',
    siteUrl: 'https://www.eldora.com',
    reCaptchaDBPropertyName: 'Eldora',
    reCaptchaPublicKey: '6LfiiYIUAAAAAEvSxGg7A_ZOQRemWMgGBoTr0Z5P',
    color: '#64CAC7',
    facebook: '',
    instagram: '',
    twitter: '',
    dor: {
      temperature: {
        name: 'EldoraMidMtn',
        api: 'sensors',
      },
      liveWeather: false,
      weatherApi: 'openweathermap',
    },
    dynamicTypes: [
      'pressRelease',
      'event',
      'blog',
    ],
    ignore404Routes: [
      '/culture/blog-and-news/blogs-and-news',
      '/things-to-do/events/all-events-programs',
    ],
  },
  // TODO: Good way of managing API keys per site, maybe use for formbucket and mailchimp?
  contentfulConfig: {
    spaceId: '38vc2unmqsq5',
    accessToken: 'D2b0ZG2uKbPvWdW38GS0y3_hQN9AxH-iJnKijjoI0cA',
  },
};
