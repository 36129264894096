import { mergeDeepLeft } from 'ramda';

import { FontSize as FontSizes, FontFamilies } from '@powdr/constants';

/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  // EXAMPLE: reg30: '100px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "jellee", sans-serif',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "jellee", sans-serif',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "CenturyGothicW01Bold", sans-serif',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "CenturyGothicW01", sans-serif',
};

export const fontTheme = {
  fonts,
  FontSize,
};
